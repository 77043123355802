import { DialogController } from 'aurelia-dialog';

export class GenerateDialog {
    static inject = [DialogController];

    constructor(controller) {
        this.controller = controller;
    }

    activate(data) {
        console.dir('AI GENERATE DATA CTX:');
        console.dir(data?.formContext);

        this.contextObjectRef = data.formContext;
    }

    ok(event) {
        this.controller.ok([event]);
    }
}
