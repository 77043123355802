import {DialogService} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {FlashService} from "../../flash/flash-service";
import {Client} from "../../api/client";
import {GenerateDialog} from "../generate/generate-dialog";

@inject(
    DialogService,
    FlashService,
    Client
)
export class ArtificialIntelligence {
    constructor(
        dialogService,
        flashService,
        client
    ) {
        this.dialogService = dialogService;
        this.flashService = flashService;
        this.client = client;
    }

    documentGenerateEnabledContexts = [
        'email/send',
        'document-generator/letter'
    ];

    setLastResponse(prompt, response) {
        let today = new Date();

        let lastResponse = {
            time: today.toLocaleTimeString() + ' ' + today.toLocaleDateString(),
            prompt: prompt,
            response: response
        };

        window.localStorage.setItem('last_artificial_intelligence_response', JSON.stringify(lastResponse));
    }

    getLastResponse() {
        let lastResponse = window.localStorage.getItem('last_artificial_intelligence_response') ?? [];

        if (lastResponse.length > 0) {
            try {
                return JSON.parse(lastResponse);
            } catch (error) {
                console.error('The last AI responses don\'t contain a valid JSON.');

                return [];
            }
        }
    }

    copyResponse(response) {
        window.navigator.clipboard.writeText(response).then(r => {
        });
    }

    async generate(ctx) {
        try {
            const dialogResponse = await new Promise((resolve, reject) => {
                this.dialogService.open({
                    viewModel: GenerateDialog,
                    model: {
                        formContext: ctx
                    }
                }).whenClosed((dialogResponse) => {
                    resolve(dialogResponse);
                });
            });

            if (!dialogResponse?.output) {
                return;
            }

            const res = dialogResponse.output[0]?.detail?.response?.data;

            if (!res) {
                console.error('Empty or no response from AI provider. Please, check your settings or given prompt.');
            }

            const historyPendingEntryId = res.id;

            const data = await this.client.get('artificial-intelligence/request/' + historyPendingEntryId);

            if (data && data.response) {
                this.flashService.success('artificial-intelligence.form.generation-request.status.success');

                this.setLastResponse(res.prompt, data.response);

                return data;
            } else {
                this.flashService.error('artificial-intelligence.form.generation-request.status.error');
            }

        } catch (error) {
            this.flashService.error(error.data.message);
            console.error('Error:', error);
        }
    }
}
